//columns.js
import { Button, Input, Tag, Avatar } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

const myUuid = uuidv4();
const findPrediction = (record, label) =>
  record.predictions.find((p) => p.label === label);

export const columns = (handleInputChange) => [
  {
    title: "Depositante",
    dataIndex: "predictions",
    key: "depositante",
    render: (text, record) => {
      const depositante = findPrediction(record, "depositante");
      return (
        <>
          <Input
            defaultValue={depositante?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {depositante?.confidence !== undefined && (
            <Tag color={depositante?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(depositante?.confidence * 100)}%
            </Tag>
          )}
          {depositante?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "depositante")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Total depósito",
    dataIndex: "predictions",
    key: "total_deposito",
    render: (text, record) => {
      const total_deposito = findPrediction(record, "total_deposito");
      return (
        <>
          <Input
            defaultValue={total_deposito?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {total_deposito?.confidence !== undefined && (
            <Tag color={total_deposito?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(total_deposito?.confidence * 100)}%
            </Tag>
          )}
          {total_deposito?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "total_deposito")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Banco depositante",
    dataIndex: "predictions",
    key: "banco",
    render: (text, record) => {
      const banco = findPrediction(record, "banco");
      return (
        <>
          <Input
            defaultValue={banco?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {banco?.confidence !== undefined && (
            <Tag color={banco?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(banco?.confidence * 100)}%
            </Tag>
          )}
          {banco?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "banco")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Agência depositante",
    dataIndex: "predictions",
    key: "agencia_depositante",
    render: (text, record) => {
      const agencia_depositante = findPrediction(record, "agencia_depositante");
      return (
        <>
          <Input
            defaultValue={agencia_depositante?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {agencia_depositante?.confidence !== undefined && (
            <Tag
              color={agencia_depositante?.confidence > 0.7 ? "green" : "red"}
            >
              {Math.round(agencia_depositante?.confidence * 100)}%
            </Tag>
          )}
          {agencia_depositante?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "agencia_depositante")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Conta depositante",
    dataIndex: "predictions",
    key: "conta_depositante",
    render: (text, record) => {
      const conta_depositante = findPrediction(record, "conta_depositante");
      return (
        <>
          <Input
            defaultValue={conta_depositante?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {conta_depositante?.confidence !== undefined && (
            <Tag color={conta_depositante?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(conta_depositante?.confidence * 100)}%
            </Tag>
          )}
          {conta_depositante?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "conta_depositante")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },

  {
    title: "Horário do depositado",
    dataIndex: "predictions",
    key: "horario_deposito",
    render: (text, record) => {
      const horario_deposito = findPrediction(record, "horario_deposito");
      return (
        <>
          <Input
            defaultValue={horario_deposito?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {horario_deposito?.confidence !== undefined && (
            <Tag color={horario_deposito?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(horario_deposito?.confidence * 100)}%
            </Tag>
          )}
          {horario_deposito?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "horario_deposito")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "CNPJ Favorecido",
    dataIndex: "predictions",
    key: "cnpj_depositado",
    render: (text, record) => {
      const cnpj_depositado = findPrediction(record, "cnpj_depositado");
      return (
        <>
          <Input
            defaultValue={cnpj_depositado?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {cnpj_depositado?.confidence !== undefined && (
            <Tag color={cnpj_depositado?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(cnpj_depositado?.confidence * 100)}%
            </Tag>
          )}
          {cnpj_depositado?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "cnpj_depositado")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Banco Favorecido",
    dataIndex: "predictions",
    key: "banco_depositado",
    render: (text, record) => {
      const banco_depositado = findPrediction(record, "banco_depositado");
      return (
        <>
          <Input
            defaultValue={banco_depositado?.value || ""}
            bordered={false}
            style={{ width: "100%" }}
          />
          {banco_depositado?.confidence !== undefined && (
            <Tag color={banco_depositado?.confidence > 0.7 ? "green" : "red"}>
              {Math.round(banco_depositado?.confidence * 100)}%
            </Tag>
          )}
          {banco_depositado?.history?.length > 0 && (
            <ClockCircleOutlined
              onClick={() => showHistory(record.myUuid, "banco_depositado")}
              style={{
                color: "#1890ff",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </>
      );
    },
  },

  {
    title: "Data e Hora",
    dataIndex: "created_time",
    key: "dataHora",
    render: (text, record) => {
      // Formatação da data e hora
      const formattedDateTime = record.created_time
        ? new Date(record.created_time).toLocaleString()
        : "N/A";

      return <>{formattedDateTime}</>;
    },
  },
  {
    title: "Inserido Por",
    dataIndex: "inseridoPor",
    key: "inseridoPor",
    render: (text, record) => {
      return record.inseridoPor ? (
        <>{record.inseridoPor}</>
      ) : (
        <Avatar
          shape="circle"
          size="small"
          src="https://pbs.twimg.com/profile_images/620977124770320384/pQcQl6ov_400x400.jpg"
        />
      );
    },
  },
];

export default columns;
