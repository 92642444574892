import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";

import PaymentCRUD from "./components/PaymentCRUD";
import UploadPage from "./components/Upload"; // Importe o componente de upload
import "./styles/app.css";

const { Header, Content } = Layout;

function App() {
  const [selectedMenuKeys, setSelectedMenuKeys] = useState([]);

  const currentPathname = window.location.pathname;

  useEffect(() => {
    const menuKeys = {
      "/": ["1"],
      "/payments": ["2"],
      "/upload": ["3"],
    };

    const selectedKeys = menuKeys[currentPathname] || [];
    setSelectedMenuKeys(selectedKeys);
  }, [currentPathname]);

  const menuItems = [
    {
      label: <Link to="/">Início</Link>,
      key: "1",
    },
    {
      label: <Link to="/payments">Pagamentos</Link>,
      key: "2",
    },
    {
      label: <Link to="/upload">Upload</Link>,
      key: "3",
    },
    // Adicione mais itens aqui conforme necessário
  ];

  return (
    <Router>
      <Layout>
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ color: "#fff", fontSize: "1.5em" }}>PayScanHub</div>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={selectedMenuKeys}
            style={{ marginLeft: "auto" }}
            items={menuItems}
          />
        </Header>
        <Content style={{ padding: "0 50px", marginTop: 64 }}>
          <div
            style={{
              padding: 24,
              minHeight: 380,
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Routes>
              <Route path="/" element={<div>Bem-vindo ao PayScanHub</div>} />
              <Route path="/payments" element={<PaymentCRUD />} />
              <Route path="/upload" element={<UploadPage />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
