import React, { useState } from "react";
import { Upload, Button, message, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const UploadComponent = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("http://192.168.1.6:5003/upload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        message.success("Upload realizado com sucesso.");
        setFileList([...fileList, file]);
        onSuccess();
      } else {
        const data = await response.text();
        message.error(data || "Erro ao fazer o upload.");
        onError();
      }
    } catch (error) {
      console.error("Erro ao fazer o upload:", error);
      message.error("Erro ao fazer o upload.");
      onError();
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f !== file);
    setFileList(newFileList);
  };

  const handleUpload = () => {
    if (fileList.length === 0) {
      message.error("Selecione um ou mais arquivos para fazer o upload.");
      return;
    }

    setUploading(true);
    setProgress(0);

    const totalFiles = fileList.length;
    let uploadedFiles = 0;

    const onProgress = (percent) => {
      setProgress(percent);
    };

    fileList.forEach(async (file) => {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch("http://192.168.1.6:5003/upload", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          uploadedFiles++;
          const percent = (uploadedFiles / totalFiles) * 100;
          onProgress(percent);

          if (uploadedFiles === totalFiles) {
            setUploading(false);
            message.success("Upload realizado com sucesso.");
            setFileList([]);
          }
        } else {
          const data = await response.text();
          message.error(data || "Erro ao fazer o upload.");
          setUploading(false);
        }
      } catch (error) {
        console.error("Erro ao fazer o upload:", error);
        message.error("Erro ao fazer o upload.");
        setUploading(false);
      }
    });
  };

  return (
    <div>
      <Upload
        customRequest={customRequest}
        onRemove={handleRemove}
        fileList={fileList}
        showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
        multiple
      >
        <Button icon={<UploadOutlined />}>Selecione Arquivos</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        style={{ marginTop: 16 }}
        icon={<UploadOutlined />}
        disabled={uploading}
      >
        Enviar
      </Button>
      {uploading && (
        <Progress
          percent={progress}
          status="active"
          style={{ marginTop: 16 }}
        />
      )}
    </div>
  );
};

export default UploadComponent;
