import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  Input,
  Spin,
  Alert,
  Empty,
  DatePicker,
} from "antd";
import { columns as defineColumns } from "./columns";
import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";

const documentsApiUrl = "http://192.168.1.6:8000/documents";
const searchApiUrl = "http://192.168.1.6:8000/search";
const itemsPerPage = 50;

const PaymentCRUD = () => {
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchDataAndRender();
  }, [currentPage, dateFilter]);

  const fetchDataAndRender = async () => {
    setLoading(true);
    setError("");

    let urlParams = new URLSearchParams({
      page: currentPage,
      per_page: itemsPerPage,
    });

    if (dateFilter) {
      urlParams.append("date", dateFilter.format("YYYY-MM-DD"));
    }

    const apiUrl = `${documentsApiUrl}?${urlParams}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();

      setData(processData(responseData));
      setTotalPages(responseData.total_pages);
    } catch (e) {
      setError(e.message);
      console.error("Erro ao buscar os dados:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setDateFilter(date);
    setCurrentPage(1);
  };

  const handleSearch = async (searchTerm) => {
    setLoading(true);
    setError("");
    setCurrentPage(1);

    let url = `${searchApiUrl}?value=${encodeURIComponent(
      searchTerm,
    )}&page=${currentPage}&per_page=${itemsPerPage}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();

      setData(processSearchData(responseData));
      setTotalPages(responseData.total_pages);
    } catch (e) {
      setError(e.message);
      console.error("Erro na busca:", e);
    } finally {
      setLoading(false);
    }
  };

  const processSearchData = (responseData) => {
    return responseData && Array.isArray(responseData)
      ? responseData.map((doc) => {
          const predictions = doc.predictions.reduce((acc, pred) => {
            acc[pred.label] = pred;
            return acc;
          }, {});
          return {
            ...doc,
            ...predictions,
            key: doc.document_id,
          };
        })
      : [];
  };

  const processData = (responseData) => {
    let processedData =
      responseData.documents && Array.isArray(responseData.documents)
        ? responseData.documents.map((doc) => ({
            ...doc,
            key: doc.id,
          }))
        : [];

    // Filtrar por data se a data estiver definida
    if (dateFilter) {
      processedData = processedData.filter((item) => {
        const itemDate = moment(
          item.created_time,
          "ddd, DD MMM YYYY HH:mm:ss GMT",
        );
        return itemDate.isSame(dateFilter, "day");
      });
    }

    return processedData;
  };

  const columns = defineColumns();

  const handleHardRefresh = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "http://192.168.1.6:8000/clear-cache",
        {
          method: "POST",
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Recarregar os dados
      fetchDataAndRender();
    } catch (e) {
      console.error("Erro ao limpar o cache e recarregar:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Input.Search
        placeholder="Buscar..."
        onSearch={handleSearch}
        enterButton
      />
      <DatePicker onChange={handleDateChange} />
      <Button
        icon={<ReloadOutlined />}
        onClick={handleHardRefresh}
        type="default"
      >
        Hard Refresh
      </Button>

      {error && (
        <Alert message="Error" description={error} type="error" showIcon />
      )}
      <Spin spinning={loading}>
        {data.length > 0 ? (
          <Table
            columns={columns}
            dataSource={data}
            rowKey="key"
            pagination={false}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum resultado encontrado"
          />
        )}
        {totalPages > 1 && (
          <Pagination
            current={currentPage}
            total={totalPages * itemsPerPage}
            onChange={(page) => setCurrentPage(page)}
            pageSize={itemsPerPage}
          />
        )}
      </Spin>
    </div>
  );
};

export default PaymentCRUD;
